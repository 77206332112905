<template>
  <div class="ui-buyTickets">
    <div class="ui-buyTickets-top">
      <a-steps :current="current">
        <a-step title="选择影院">
        </a-step>
        <a-step title="选择影片和排期">
        </a-step>
        <a-step title="座位选择">
        </a-step>
        <a-step title="支付">
        </a-step>
      </a-steps>
    </div>
    <div class="ui-buyTickets-content">
      <selectCinema @changeCurrent="changeCurrent" :cinemaData='pageData' :userPhone='phone' v-if="current == 0"></selectCinema>
      <selectMovie @changeCurrent="changeCurrent" :cinemaData='pageData' :userPhone='phone' v-if="current == 1"></selectMovie>
      <selectSeat @changeCurrent="changeCurrent" :movieData='pageData' :userPhone='phone' v-if="current == 2"></selectSeat>
      <selectPay @changeCurrent="changeCurrent" :seatData='pageData' :userPhone='phone' v-if="current == 3"></selectPay>
    </div>
  </div>

</template>

<script>
import selectCinema from "@/views/buyTickets/selectCinema.vue";
import selectMovie from "@/views/buyTickets/selectMovie.vue";
import selectPay from "@/views/buyTickets/selectPay.vue";
import selectSeat from "@/views/buyTickets/selectSeat.vue";
export default {
  components: { selectCinema,selectMovie,selectPay,selectSeat},
  data() {
    return {
      current: 0,
      pageData:{},
      phone:''
    }
  },
  methods: {
    changeCurrent(data){
      this.current = data.index
      this.pageData ={...data.cinemaData,...data.movieData,...data.seatData}
      console.log(JSON.stringify(this.pageData))
      this.phone = data.phone
    },

  },
};
</script>
<style scoped>
.ui-buyTickets{
  width: 1000px;
  margin: 0 auto;
}
.ui-buyTickets-top{
  width: 1000px;
}
.ui-buyTickets-content{
  margin-top: 20px;
  height: 500px;
}

</style>

