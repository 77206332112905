<template>
  <a-spin :spinning="loading">
    <div>
      <!--    <a-form ref="form" :model="formState" name="form" layout="horizontal">
              <a-form-item class="ui-form__item" label="用户手机号" name="phone"  style="width: 280px;position: relative;left: 620px;">
                <a-input
                  placeholder="请输入用户手机号"
                  v-model:value="phone"
                ></a-input>
              </a-form-item>
          </a-form>-->
      <div class="seat-detail">
        <div class="ui-seat__wrap">
          <div class="seatType">
            <div class="seatType-item">
              <img src="../../assets/seatImg/seat_empty.png">
              <text>可选座位</text>
            </div>
            <div class="seatType-item">
              <img src="../../assets/seatImg/seat_sell.png">
              <text>已售座位</text>

            </div>
            <div class="seatType-item">
              <img src="../../assets/seatImg/seat_select.png">
              <text>已选座位</text>

            </div>
            <div class="seatType-item">
              <img src="../../assets/seatImg/seat_love.png">
              <text>情侣座位</text>
            </div>
          </div>
          <div class="rowNum" >
            <div class="rowNum-item" v-for="(item, index) in maxRow" v-show="index >= emptyRow" :key="index">{{ seatMap[index + 1] || '' }}</div>
          </div>
          <div class="ui-seat__box">
            <div class="seat" :style="{width:maxColumn * 40 + 15+ 'px',height:maxRow * 40 + 70+ 'px'}">
              <div class="allSeat" :style="{width:maxColumn * 39 + 15+ 'px'}">
                <div style="position: absolute;left: 50%;transform: translate(-50%);">屏幕</div>
                <!--          <div class="ui-seat__screen" style="width: 100%">-->
                <!--            <div class="ui-seat__screenText">-->
                <!--              银幕中央-->
                <!--              <div class="ui-seat__screenLine" :style="{ height: maxRow * 30 + 'px' }"></div>-->
                <!--            </div>-->
                <!--          </div>-->
                <div class="ui-seat__item" v-for="(allitem,index) in allSeatList" :key="allitem.seatCode"
                     :style="{ marginLeft: (Number(allitem.colNum) - 1) * 40 + 'px', marginTop: Number(allitem.rowNum - emptyRow) * 40 + 'px' }"
                     @click.stop="() => {onSeleSeat(allitem)}"
                >
                  <img v-if="allitem.seatIsDamaged || allitem.seatType === 'H'" src="../../assets/seatImg/lock.png">
                  <img v-else-if="allitem.isBuy" src="../../assets/seatImg/seat_sell.png">
                  <img v-else-if="allitem.isSelect && (allitem.seatType === 'L' || allitem.seatType === 'R')" src="../../assets/seatImg/seat_select_love.png">
                  <img v-else-if="allitem.isSelect" src="../../assets/seatImg/seat_select.png">
                  <img v-else-if="allitem.seatType === 'L' || allitem.seatType === 'R'" src="../../assets/seatImg/seat_love.png">
                  <img v-else src="../../assets/seatImg/seat_empty.png">

                </div>
              </div>

            </div>
          </div>

        </div>
        <div class="movie">
          <div class="movie-top">
            <div class="movie-img">
              <img :src="detail.imageUrl" />
            </div>
            <div class="movie-name">
              <div class="name">{{detail.name || ''}}</div>
              <div class="type" v-if="detail.classifyInfo"><span>类型：</span>{{movieClass(detail.classifyInfo)}}</div>
              <div class="time" v-if="detail.duration"><span>时长：</span>{{detail.duration}}分钟</div>
            </div>
          </div>
          <div class="movie-detail">
            <div><span>影院：</span>{{movieData?.cinemaData?.cinemaName || ''}}</div>
            <div><span>影厅：</span>{{ detail.hallName || '' }}</div>
            <div><span>版本：</span>{{ detail.language || '' }}{{ detail.dimensional || '' }}</div>
            <div><span>场次：</span>{{movieData?.timeDetail?.title || ''}}&nbsp;&nbsp;&nbsp;{{transDate(detail.startTime,3) || ''}}</div>
            <div><span>票价：</span>￥{{detail.settlePrice}}/张</div>
            <div class="movie-seat"><span>座位：</span><div> <text class="seatSelect" v-for="(item, index) in selectSeatList" :key="item.id">{{ item.rowId }}排{{ item.colId }}座</text></div>
            </div>
            <div class="movie-total"><span>总价：</span>￥{{ Number(detail.settlePrice * selectSeatList.length).toFixed(2) }}</div>
          </div>

		  <div class="movie-options">
		    <a-button @click="preBtn">上一步</a-button>
		    <a-button type="primary" @click="onNextBtnTap">确认订单</a-button>
		  </div>
        </div>
      </div>
      <a-modal
        title="提示"
        :visible="visible"
        @ok="handleOk"
        @cancel="handleCancel"
      >
        <p>{{ ModalText }}</p>
      </a-modal>
    </div>
  </a-spin>
</template>

<script>
import {
  getShowInfo,
  getSeatInfo,
  getAllocate,
  lockSeat,
  preOrderTicket,
  releaseSeat
} from "@/service/modules/buyTickets";
import movieClass from "./utils/movieClass";
import transDate from "./utils/transDate";
import envenBus from '@/utils/eventBus'
export default {
  props: {
    movieData: {
      type: Object,
      default () {
        return {}
      }
    },
    userPhone: {
      type: String,
      default () {
        return ''
      }
    }
  },
  data() {
    return {
      formState:{},
      detail:{},
      allSeatList: [],
      seatMap: {},
      maxRow: 0,
      maxColumn: 0,
      emptyRow: 0,	// 前排空行数
      sellSeatList: null,
      selectSeatList: [],	// 选中的座位
      saleMaxTickets: 4,
      ModalText: '',//提示语
      visible: false,//控制展示
      isLock: false, 	//是否已锁座返回
      countDownTime: 0,	// 锁座倒计时，
      myInfo: {},//用户信息
      ticketData:{},//预下单信息
      // getInfoTimeout: false,
      fristInit: true,
      phone:'',
      itemArry:[],
      loading: false,
    }
  },
  created() {
    if (this.userPhone){
      this.phone = this.userPhone
    }
    this.loading = true;
    this.getData()
    this.getSeatInfo()
    this.saleMaxTickets = Number(this.movieData?.saleMaxTickets) || 4;
    envenBus.$on('releaseSeat', (data)=> {
      // 座位已被释放或订单已支付成功，返回页面不需要弹窗,同时把座位转为已售状态
      // console.log(data,"datadatadatadata")
      if (data.orderSuccess) {
        this.selectSeatList.forEach(item => {
          // this.$set(this.allSeatList[item.index], 'isSelect', false);
          this.allSeatList[item.index] = Object.assign({}, this.allSeatList[item.index], { isSelect: false });

          // this.$set(this.allSeatList[item.index], 'isBuy', true);
          this.allSeatList[item.index] = Object.assign({}, this.allSeatList[item.index], { isBuy: true });

        });
        this.selectSeatList = [];
      }
      if (data.orderId != undefined) {	// 有订单ID时，取消付款弹窗走关闭订单接口，否则释放座位
        this.orderId = data.orderId;
      }
      if (data.time) {
        this.countDownTime = Number(data.time);
      }
      this.isLock = data.isLock;
      if (!this.isLock) {
        if (this.timeout) {
          clearTimeout(this.timeout);
        }
      }
    });

    if (this.isLock) {
      //  已锁座未购买
      // this.$refs.lockPopup.open('center');
    } else if (!this.fristInit) {
      this.getAllocate();
    }
    this.fristInit = false;
  },
  methods: {
    scrollToPosition() {
      this.$refs.scrollContainer.scrollLeft = 100
    },
    //获取当前电影演出信息
    async getData() {
      //  播放场次信息
      let ret = await getShowInfo({
        id: this.movieData?.id
      });
      if (ret.code === 200) {
        this.detail = ret.data;
        this.getAllocate();

      }
    },
    async getSeatInfo() {
      //  座位信息
      // this.showSeat = false;
      let ret = await getSeatInfo({
        id: this.movieData?.id
      });
      if (ret.code === 200) {
        let allSeatList = [];
        ret.data.forEach(item => {
          allSeatList = this.allSeatList.concat(item.cinemaSeatsJsonVOS);
        });
        this.maxRow = 0;
        this.maxColumn = 0;
        if (this.detail.ticketSystem != 2) {
          this.allSeatList = allSeatList;
          this.allSeatList.forEach((item, index) => {
            this.seatMap[Number(item.rowNum)] = item.rowId;
            item.isSelect = false;
            item.index = index;
            if (Number(item.colNum) > this.maxColumn) {
              this.maxColumn = Number(item.colNum);
            }

            if (Number(item.rowNum) > this.maxRow) {
              this.maxRow = Number(item.rowNum);
            }
          })
          let keys = Object.keys(this.seatMap).map(item=> (Number(item))).sort((a, b) => { return a - b });
          // 计算前排是否有空行，有则往前定位；
          if (keys.length) {
            this.emptyRow = keys[0] - 1;
          }
          this.transSeatData();
        } else if (this.detail.ticketSystem == 2) {
          // 辰星座位处理
          // 0.提取 allSeatList 的 UL_X 和 UL_Y
          const xList = [];
          const yList = [];
          allSeatList.forEach(item=>{
            if (!xList.includes(item.ulX)) {
              xList.push(item.ulX)
            }
            if (!yList.includes(item.ulY)) {
              yList.push(item.ulY)
            }
          });
          xList.sort((x, y) => x - y);
          yList.sort((x, y) => x - y);
          // 1.求x和y的最大公约数
          const xGCD = this.getGCD(xList);
          const yGCD = this.getGCD(yList);
          // 2.x和y补齐等差数列的空缺
          const xArithmeticSequence = this.getArithmeticSequence(xList, xGCD);
          const yArithmeticSequence = this.getArithmeticSequence(yList, yGCD);
          // 3.对num进行重新编码
          const col = {};
          const row = {};
          xArithmeticSequence.forEach((item,index) => { col[item] = index+1});
          yArithmeticSequence.forEach((item,index) => { row[item] = index+1});
          // 4.修改allSeatList的colNum和rowNum
          allSeatList.forEach(item => {
            item.colNum = col[item.ulX];
            item.rowNum = row[item.ulY];
          })
          // 5. 常规逻辑处理, 同佳影
          this.allSeatList = allSeatList;
          this.allSeatList.forEach((item, index) => {
            this.seatMap[Number(item.rowNum)] = item.rowId;
            item.isSelect = false;
            item.index = index;
            if (Number(item.colNum) > this.maxColumn) {
              this.maxColumn = Number(item.colNum);
            }

            if (Number(item.rowNum) > this.maxRow) {
              this.maxRow = Number(item.rowNum);
            }
          })
          let keys = Object.keys(this.seatMap).map(item=> (Number(item))).sort((a, b) => { return a - b });
          // 计算前排是否有空行，有则往前定位；
          if (keys.length) {
            this.emptyRow = keys[0] - 1;
          }
          this.transSeatData();
        }

      }
    },
    /*
       * 求最大公约数
       * param Array 数组
       */
    getGCD(numbers) {
      // 获取最大公约数
      const gcd = (a, b) => {
        while (b!== 0) {
          let remainder = a % b;
          a = b;
          b = remainder;
        }
        return a;
      }
      let result = numbers[0];
      for (let i = 1; i < numbers.length; i++) {
        result = gcd(result, numbers[i]);
      }
      return result;
    },
    /*
     * 求最大公约数
     * param list Array 数字数组 [0, 2, 4] 要求符合公差
     * param difference Number 公差
     */
    getArithmeticSequence(list, difference) {
      const min = Math.min(...list);
      const max = Math.max(...list);
      const sequence = [];
      for (let current = min; current <= max; current += difference) {
        sequence.push(current);
      }
      return sequence;
    },
    async getAllocate() {
      // 已售座位信息
      let ret = await getAllocate({
        showId: this.movieData?.id,
        filmCode: this.detail.filmCode,
        hallCode: this.detail.hallCode,
        sectionId: this.detail.sectionId
      });
      if (ret.code === 200) {
        this.sellSeatList = ret.data;
        this.transSeatData();
      }
      this.loading = false;
    },
    transSeatData() {
      if (this.allSeatList.length && this.sellSeatList) {
        //  对已售座位进行整合
        this.allSeatList.forEach((item, index) => {
          // this.$set(this.allSeatList[index], 'isBuy', false);
          this.allSeatList[index] = Object.assign({}, this.allSeatList[index], { isBuy: false });
          if (this.sellSeatList.length) {
            this.sellSeatList.forEach(subItem => {
              if (item.colId === subItem.colId && item.rowId === subItem.rowId) {
                // this.$set(this.allSeatList[index], 'isBuy', true);
                this.allSeatList[index] = Object.assign({}, this.allSeatList[index], { isBuy: true });
              }
            })
          }

        })
      }
      this.showSeat = true;
      if (this.isFirst) {
        this.isFirst = false;
        if (this.uiVersion === 1) {
          this.openQueryModal();
        }
      }
    },
    onSeleSeat(seatItem) {
      if (seatItem.seatIsDamaged || seatItem.isBuy || seatItem.seatType === 'H') return;
      let seatIndex = -1;
      this.selectSeatList.forEach((item, index) => {
        if (item.seatCode === seatItem.seatCode) {
          //  已选中，反选
          seatIndex = index;
        }
      });
      const direction = this.getRowDirection(seatItem);
      if (seatIndex >= 0) {
        // this.$set(this.allSeatList[seatItem.index], 'isSelect', false);
        // Vue 2 和 Vue 3 通用
        this.allSeatList[seatItem.index] = Object.assign({}, this.allSeatList[seatItem.index], { isSelect: false });
        this.selectSeatList.splice(seatIndex, 1);
        if (seatItem.seatType === 'L' || seatItem.seatType === 'R') {
          this.selectSeatList.forEach((item, index) => {
            if (item.rowId === seatItem.rowId) {
              if (seatItem.seatType === 'L' && Number(item.colId) === Number(seatItem.colId) + 1 && direction == 'left') {
                // this.$set(this.allSeatList[item.index], 'isSelect', false);
                this.allSeatList[item.index] = Object.assign({}, this.allSeatList[item.index], { isSelect: false });

                this.selectSeatList.splice(index, 1);
              }
              if (seatItem.seatType === 'L' && Number(item.colId) === Number(seatItem.colId) - 1 && direction == 'right') {
                // this.$set(this.allSeatList[item.index], 'isSelect', false);
                this.allSeatList[item.index] = Object.assign({}, this.allSeatList[item.index], { isSelect: false });

                this.selectSeatList.splice(index, 1);
              }
              if (seatItem.seatType === 'R' && Number(item.colId) === Number(seatItem.colId) - 1 && direction == 'left') {
                // this.$set(this.allSeatList[item.index], 'isSelect', false);
                this.allSeatList[item.index] = Object.assign({}, this.allSeatList[item.index], { isSelect: false });

                this.selectSeatList.splice(index, 1);
              }
              if (seatItem.seatType === 'R' && Number(item.colId) === Number(seatItem.colId) + 1 && direction == 'right') {
                // this.$set(this.allSeatList[item.index], 'isSelect', false);
                this.allSeatList[item.index] = Object.assign({}, this.allSeatList[item.index], { isSelect: false });

                this.selectSeatList.splice(index, 1);
              }
            }
          })
        }
      } else {
        let length = this.selectSeatList.length;
        if (seatItem.seatType === "R" || seatItem.seatType === "L") {
          length += 2;
        } else {
          length += 1;
        }
        if (length > this.saleMaxTickets) {
          this.ModalText = '最多限购' + this.saleMaxTickets + '个座位'
          this.visible = true;
          return;
        }
        // this.$set(this.allSeatList[seatItem.index], 'isSelect', true);
        this.allSeatList[seatItem.index] = Object.assign({}, this.allSeatList[seatItem.index], { isSelect: true });

        if (seatItem.seatType === "R") {
          this.allSeatList.forEach(item => {
            if (item.rowId === seatItem.rowId) {
              if (Number(item.colId) === Number(seatItem.colId) - 1 && direction == 'left') {
                this.selectSeatList.push(item);
                item.isSelect = true;
              }
              if (Number(item.colId) === Number(seatItem.colId) + 1 && direction == 'right') {
                this.selectSeatList.push(item);
                item.isSelect = true;
              }
            }
          })
        }
        this.selectSeatList.push(seatItem);
        if (seatItem.seatType === "L") {
          this.allSeatList.forEach(item => {
            if (item.rowId === seatItem.rowId) {
              if (Number(item.colId) === Number(seatItem.colId) + 1 && direction == 'left') {
                this.selectSeatList.push(item);
                item.isSelect = true;
              }
              if (Number(item.colId) === Number(seatItem.colId) - 1 && direction == 'right') {
                this.selectSeatList.push(item);
                item.isSelect = true;
              }
            }
          })
        }
      }
    },
    getRowDirection(seatItem) {
      // 1.先判断方向
      let direction = 'left';
      const allSeatList = this.allSeatList;
      // 1.1 直接找同一行的俩座位判断即可
      const sameRow = allSeatList.filter(item => {
        return seatItem.rowId == item.rowId;
      });
      const col1 = sameRow[0];
      const col2 = sameRow[1];
	  if (col2) {
		 if (Number(col1.colId) < Number(col2.colId) && Number(col1.colNum) < Number(col2.colNum)) {
		   // 座位从左往右排 1,2,3,4,5,6
		   direction = 'left';
		 } else if (Number(col1.colId) < Number(col2.colId) && Number(col1.colNum) > Number(col2.colNum)) {
		   // 座位从右往左排 6,5,4,3,2,1
		   direction = 'right';
		 } else if (Number(col1.colId) > Number(col2.colId) && Number(col1.colNum) < Number(col2.colNum)) {
		   direction = 'right';
		 } else if (Number(col1.colId) > Number(col2.colId) && Number(col1.colNum) > Number(col2.colNum)) {
		   direction = 'left';
		 } 
	  }
      return direction
    },
    async onLockSeat() {
      let seatCodes = this.selectSeatList.map(item => item.seatCode).join(',');
      if (!seatCodes) {
        this.$message.error('请选择座位');
        return
      }
      let ret = await lockSeat({
        showId: this.movieData?.id,
        seatCodes: seatCodes
      });
      if (ret.code === 200) {
        let result = {};
        result = await preOrderTicket({
          showId: this.movieData.id,
          seatCodes: seatCodes,
          cinemaId: this.movieData?.cinemaData?.cinemaId,
          storeId: this.movieData?.cinemaData?.storeId,
          source:3

        });
        if (result.code === 200) {
          this.isLock = true;
          this.countDownTime = result.data.lockSeatTime;
          // this.ticketData = result.data
          this.ticketData = this.movieData
          this.ticketData.ticketData = result.data
          // console.log(this.ticketData,"this.ticketDatathis.ticketData")
          this.$emit("changeCurrent",{index:3,seatData:this.ticketData,phone:this.phone})
          setTimeout(() => {
            this.setCountdown();
          }, 1000);
        }
      } else {

      }
    },
    setCountdown() {
      if (this.countDownTime > 0) {
        this.countDownTime -= 1;
      } else {
        this.orderId = 0;
        this.isLock = false;
      }
    },
    async onCancelLock() {
        let ret = await releaseSeat({
          showId: this.movieData?.id,
          seatCodes: this.selectSeatList.map(item => item.seatCode).join(',')

        });
        if (ret.code === 200) {
          this.isLock = false;
          // this.$refs.lockPopup.close();
        }
      },
    onDeleteSeat(seatItem, index) {
      // this.$set(this.allSeatList[seatItem.index], 'isSelect', false);
      this.allSeatList[seatItem.index] = Object.assign({}, this.allSeatList[seatItem.index], { isSelect: false });

      this.selectSeatList.splice(index, 1);
      if (seatItem.seatType === 'L' || seatItem.seatType === 'R') {
        const direction = this.getRowDirection(seatItem);
        this.selectSeatList.forEach((item, index) => {
          if (item.rowId === seatItem.rowId) {
            if (seatItem.seatType === 'L' && Number(item.colId) === Number(seatItem.colId) + 1 && direction == 'left') {
              // this.$set(this.allSeatList[item.index], 'isSelect', false);
              this.allSeatList[item.index] = Object.assign({}, this.allSeatList[item.index], { isSelect: false });

              this.selectSeatList.splice(index, 1);
            }
            if (seatItem.seatType === 'R' && Number(item.colId) === Number(seatItem.colId) - 1 && direction == 'left') {
              // this.$set(this.allSeatList[item.index], 'isSelect', false);
              this.allSeatList[item.index] = Object.assign({}, this.allSeatList[item.index], { isSelect: false });

              this.selectSeatList.splice(index, 1);
            }
            if (seatItem.seatType === 'L' && Number(item.colId) === Number(seatItem.colId) - 1 && direction == 'right') {
              // this.$set(this.allSeatList[item.index], 'isSelect', false);
              this.allSeatList[item.index] = Object.assign({}, this.allSeatList[item.index], { isSelect: false });

              this.selectSeatList.splice(index, 1);
            }
            if (seatItem.seatType === 'R' && Number(item.colId) === Number(seatItem.colId) + 1 && direction == 'right') {
              this.allSeatList[item.index] = Object.assign({}, this.allSeatList[item.index], { isSelect: false });

              // this.$set(this.allSeatList[item.index], 'isSelect', false);
              this.selectSeatList.splice(index, 1);
            }
          }
        })
      }
    },


    preBtn(){
      this.$emit("changeCurrent",{index:1,cinemaData:this.movieData?.cinemaData,phone:this.phone})
    },
    onNextBtnTap(){
      this.onLockSeat()
      // console.log(this.ticketData,"this.ticketData,this.ticketData")
    },
    handleCancel() {
      this.visible = false;
    },
    handleOk(){
      this.visible = false;
    },
    movieClass,
    transDate

  },
  beforeUnmount() {
    envenBus.$off('releaseSeat');
  }

};
</script>
<style scoped>

.seat-detail{
  display: flex;
  margin: 0 auto;
  position: relative;
}

.ui-seat__wrap {
  position: relative;
  width: 700px;
  display: flex;
  border: 1px solid #f1f1f1;
}

.ui-seat__box {
    position: relative;
    /* top: 100px; */
    /* width: 560px; */
    /* height: 375px; */
    overflow-x: scroll;
    /* overflow-y: scroll; */
}

/* 针对WebKit浏览器，自定义滚动条样式 */
::-webkit-scrollbar {
    width: 12px;  /* 设置滚动条的宽度 */
    height: 12px; /* 设置滚动条的高度 */
}

::-webkit-scrollbar-track {
    background: #f1f1f1; /* 滚动条轨道的背景颜色 */
}

::-webkit-scrollbar-thumb {
    background: #888; /* 滚动条实际可拖动部分的颜色 */
}

::-webkit-scrollbar-thumb:hover {
    background: #555; /* 滚动条实际可拖动部分的颜色，鼠标悬停时 */
}

.seat{
    width:100%;
	margin-top: 100px;
}
.movie{
  width: 450px;
  padding-left: 50px;
  padding-top: 20px;
  border: 1px solid #f1f1f1;
}
.movie-options{
  width: 200px;
  display: flex;
  justify-content: space-around;
  margin-bottom: 20px;
  margin-left: 40px;
  margin-top: 50px;
  /* position: absolute; */
  /* left: 50%; */
  /* transform: translate(-50%); */
  /* bottom: -70px; */
}
.movie-top{
  display: flex;
  padding-bottom: 20px;
}
.movie-img{
  width: 90px;
  height: 131px;
  background-color: #fff;
}
.movie-img img{
  width: 100%;
  height: 100%;
}
.movie-name{
  padding-left: 20px;

}
.movie-name .name{
  font-size: 25px;
  font-weight: bold;
  padding-bottom: 10px;

}
.movie-name{
  padding-left: 20px;

}
.movie-name{
  padding-left: 20px;

}
.movie span{
  font-size: 15px;
  color: #b0b0b0;
}
.movie-detail div{
  margin: 10px 0;
}
.movie-detail .movie-seat{
  margin: 30px 0;
}
.movie-detail .movie-total{
  margin-top: 50px;
}

.ui-seat__item img {
  width: 100%;
  height: 100%;
  margin: 10px 0px 10px 15px;
  //background-color: #1890ff;
}
.ui-seat__item {
  height: 30px;
  width: 30px;
  position: absolute;
  //left: 100px;
  //display: inline-block;



}

.ui-seat__item img {
  width: 100%;
  height: 100%;
}
ui-seat__screenText {
  font-size: 20px;
  height: 40px;
  //background: red;
  border-radius: 10px;
  font-size: 28px;
  text-align: center;
  background-color: rgba(0, 0, 0, .3);
  color: #fff;
  line-height: 40px;
  position: relative;
}
.ui-seat__screen {
  width: 98px;
  position: absolute;
  //left: 50%;
  //transform: translateX(-50%);
  text-align: center;
}
.allSeat{
  //height: 100%;
  position: relative;
  //left: 100px

}
.rowNum{
  height: fit-content;
  width: 50px;
  //position: absolute;

  //background-color: #1890ff;
  margin-top: 150px;
}
.rowNum-item{
  width: 100%;
  height: 40px;
  text-align: center;
  line-height: 30px;
}
.seatBox {
  padding-top: 10px;
  background-color: red;
  width: 250px;

  /* align-items: center; */
  /* justify-content: center; */
}
.seatSelect {
  padding-left: 15px;
}
.ui-seatSelect__text {
  font-size: 12px;
  line-height: 8px;
  white-space: nowrap;
}
.seatType{
  position: absolute;
  display: flex;
  width: 430px;
  //background-color: red;
  top: 30px;
  left: 20px;
}
.seatType-item{
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 10px;
}
.seatType-item img{
    width: 30px;
    height: 30px;
}


</style>
